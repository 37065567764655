import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';

import { ReactElement } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCoffee, faFacebook } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function Header() {
  return (
    <>
      <header className='fixed-top'>
        <Container fluid className='h-100'>
          <Row className='d-flex justity-content-end h-100'>
            <Col xs={8} lg={10} xl={11} className='p-0'>
              {['xxl'].map((expand) => (
                <Navbar bg="dark" variant="dark" expand={expand} sticky="top" className='h-100'>
                  <Container fluid>
                    <Navbar.Brand href="/">
                      <Link to="/">
                        <img src="https://cdn.agenciaenos.com/uploads/logo-enos-white.svg" alt="Logo Enos"
                          width={150}
                          height={40} />
                      </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='d-none' />

                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end" className='d-none'
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                          Offcanvas
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">

                          <Link to="/">
                            <Nav.Link href="/">Home</Nav.Link>
                          </Link>
                          <Link to="/about"><Nav.Link href="/about">About</Nav.Link></Link>
                          <Link to="/services"><Nav.Link href="/services">Services</Nav.Link></Link>
                        </Nav>

                      </Offcanvas.Body>
                    </Navbar.Offcanvas>


                  </Container>
                </Navbar>
              ))}
            </Col>
            <Col xs={4} lg={2} xl={1} className='justify-content-end'>
              <div className="btnsHead d-flex align-items-center h-100 justify-content-end">
                <a href="https://www.facebook.com/people/enos-growth/100076230460514/" target={"_blank"}
                  rel={"noreferrer"}><FontAwesomeIcon icon={faFacebookF} className="icoHead" /></a>
                <a href="https://www.linkedin.com/company/80322706/" target={"_blank"}
                  rel={"noreferrer"}><FontAwesomeIcon icon={faLinkedinIn} className="icoHead" /></a>
                <a href="https://api.whatsapp.com/send?phone=525579984418" target={"_blank"}
                  rel={"noreferrer"}><FontAwesomeIcon icon={faWhatsapp} className="icoHead" /></a>

              </div>
            </Col>
          </Row>
        </Container>


      </header>
    </>
  );
}
