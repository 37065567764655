
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { motion } from "framer-motion";


import Header from "../../components/Header/Header.js";
import RemoteNavigation from "../../components/RemoteNavigation/RemoteNavigation.js";



export default function ThanksPage() {
  React.useEffect(() => {
    document.body.classList.toggle("thanks-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("thanks-page");
    };
  }, []);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Servicios</title>
        <meta name="description" content="Description Servicios"></meta>
        <link rel="canonical" href="https://agenciaenos.com/thanks" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Header />
         <div className="bg bgContacto"></div>
        <div className="heroBackdropOne"></div>
        <div className="heroBackdrop"></div>
        <motion.main
        initial={{ opacity: 0, x: -200, y: 0 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, x: 0, y: -100 }}
        transition={{ type: 'linear' }}
      >
        <section className="section">
          <Container>
            <Row>
              <Col>
                <div className="w-md-75 d-inline-block">
                  <h1 className="text-center text-white">Gracias</h1>
                  <p className="text-center text-white">Gracias por enviar tu solicitud, pronto te contactaremos</p>
                  <div className="text-center">
                    <a href="/contacto">
                      <Link to="/contacto"><Button variant="primary">Regresar</Button></Link></a>
                  </div>
                  
                </div>
                
                <RemoteNavigation />
              </Col>
            </Row>
          </Container>
        </section>
      </motion.main>
    </>
  );
}
