import { React, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';

import { ReactElement } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCoffee, faFacebook } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { useLocation } from "react-router-dom";



export default function RemoteNavigation() {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");


    return (
        <>
            <div className="remoteItemNavigation">
                <div className={splitLocation[1] === "" ? "itemButton itemButtonActive" : "itemButton"}>
                    <div className="itemIcon">
                        <a href="/">
                            <Link to="/"><img
                                src="https://cdn.agenciaenos.com/uploads/icoInicio.svg"
                                alt="Inicio"
                                width="100%"
                                height="100%"
                            /></Link>
                        </a>
                    </div>
                    <div className="itemLabel">Inicio </div>
                </div>
                <div className={splitLocation[1] === "nosotros" ? "itemButton itemButtonActive" : "itemButton"}>
                    <div className="itemIcon">
                        <a href="/nosotros">
                            <Link to="/nosotros"><img
                                src="https://cdn.agenciaenos.com/uploads/icoNosotros.svg"
                                alt="Inicio"
                                width="100%"
                                height="100%"
                            /></Link>
                        </a>
                    </div>
                    <div className="itemLabel">Nosotros</div>
                </div>
                <div className={splitLocation[1] === "equipo" ? "itemButton itemButtonActive" : "itemButton"}>
                    <div className="itemIcon">
                        <a href="/equipo">
                            <Link to="/equipo"><img
                                src="https://cdn.agenciaenos.com/uploads/icoEquipo.svg"
                                alt="Equipo"
                                width="100%"
                                height="100%"
                            /></Link>
                        </a>
                    </div>
                    <div className="itemLabel">El Equipo</div>
                </div>
                <div className={splitLocation[1] === "servicios" ? "itemButton itemButtonActive" : "itemButton"}>
                    <div className="itemIcon">
                        <a href="/servicios">
                            <Link to="/servicios"><img
                                src="https://cdn.agenciaenos.com/uploads/icoServicios.svg"
                                alt="Servicios"
                                width="100%"
                                height="100%"
                            /></Link>
                        </a>
                    </div>
                    <div className="itemLabel">Servicios</div>
                </div>
                <div className={splitLocation[1] === "contacto" ? "itemButton itemButtonActive" : "itemButton"}>
                    <div className="itemIcon">
                        <a href="/contacto">
                            <Link to="/contacto"><img
                                src="https://cdn.agenciaenos.com/uploads/icoContacto.svg"
                                alt="Contacto"
                                width="100%"
                                height="100%"
                            /></Link>
                        </a>
                    </div>
                    <div className="itemLabel">Contacto</div>
                </div>
            </div>
        </>
    );
}