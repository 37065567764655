
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { motion } from "framer-motion";


import Header from "./../../components/Header/Header.js";
import RemoteNavigation from "../../components/RemoteNavigation/RemoteNavigation.js";



export default function NosotrosPage() {
  React.useEffect(() => {
    document.body.classList.toggle("nosotros-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("nosotros-page");
    };
  }, []);
  return (
    <>
   <Helmet>
                <meta charSet="utf-8" />
                <title>Enos - Nosotros</title>
                <meta name="description" content="Somos un grupo de expertos seleccionados en growth marketing, dirección de arte, desarrollo web, growth advisory, análisis de datos e inteligencia de negocios." />
                <link rel="canonical" href="https://agenciaenos.com/nosotros" />
                <meta property="og:locale" content="es_MX" />
		<meta property="og:site_name" content="enos - Exploradores • Numéricos • Observadores • Sistemáticos" />
            </Helmet>
            <Header />
        
                <div className="bg bgNosotros"></div>
                <div className="heroBackdropOne"></div>
                <div className="heroBackdrop"></div>
                <motion.main
                initial={{ opacity: 0, x: -200, y: 0 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0, x: 0, y: -100 }}
                transition={{ type: 'linear' }}
            >
                <section className="section">
                    <Container className="d-flex align-items-center">
                        <Row>
                            <Col>
                            <div className="w-md-75 d-inline-block">
                                <h1 className="text-center text-white">Somos pioneros</h1>
                                <p className="text-center text-white">Siempre que exista la voluntad de crear e innovar, podremos hacer algo completamente diferente a los demás, incluso en un mercado acelerado. Los cosmonautas de enos tienen experiencia en todos los rubros que necesites: marketing digital, dirección de arte, desarrollo web, análisis de datos e inteligencia de negocios.</p>
                                <div className="text-center">
                                    <a href="/contacto">
                                        <Link to="/contacto"><Button variant="primary"> Contacto</Button></Link></a>
                                </div>
                                </div>
                                <div className="w-md-75 mt-4 pt-4 d-inline-block">
                                <Row className="d-flex align-items-center">
                                  <Col><img src="https://cdn.agenciaenos.com/uploads/logos-clientes/alai.png" width={100} className="logoCliente"/></Col>
                                  <Col><img src="https://cdn.agenciaenos.com/uploads/logos-clientes/consupago.png" width={100} className="logoCliente"/></Col>
                                  <Col><img src="https://cdn.agenciaenos.com/uploads/logos-clientes/doncapitan.png" width={100} className="logoCliente"/></Col>
                                  <Col><img src="https://cdn.agenciaenos.com/uploads/logos-clientes/grupomarzam.png" width={100} className="logoCliente"/></Col>
                                  <Col><img src="https://cdn.agenciaenos.com/uploads/logos-clientes/fiestaamericana.png" width={100} className="logoCliente"/></Col>
                                </Row>
                                </div>
                                
                                <RemoteNavigation/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </motion.main>
    </>
  );
}
