import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Switch, Redirect  } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import Index from "./views/Index";
import NosotrosPage from "./views/pages/NosotrosPage";
import EquipoPage from "./views/pages/EquipoPage";
import ServiciosPage from "./views/pages/ServiciosPage";
import ContactoPage from "./views/pages/ContactoPage";
import ErrorFormPage from "./views/pages/ErrorFormPage";
import ThanksPage from "./views/pages/ThanksPage";
import Error404Page from "./views/pages/Error404Page";





const enos = ReactDOM.createRoot(document.getElementById('enos'));
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/
enos.render(
<BrowserRouter>
     <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route path="/nosotros">
            <NosotrosPage />
          </Route>
          <Route path="/equipo">
            <EquipoPage />
          </Route>
          <Route path="/servicios">
            <ServiciosPage />
          </Route>
          <Route path="/contacto">
            <ContactoPage />
          </Route>
          <Route path="/errorform">
            <ErrorFormPage />
          </Route>
          <Route path="/thanks">
            <ThanksPage />
          </Route>
          <Route path="*">
            <Error404Page />
          </Route>
        </Switch>
  </BrowserRouter>
    
         
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
