
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Form, FloatingLabel, Card } from 'react-bootstrap';
import { motion } from "framer-motion";


import Header from "./../../components/Header/Header.js";
import RemoteNavigation from "../../components/RemoteNavigation/RemoteNavigation.js";

import { faFacebookF, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*
<video className="video"
                    controls={false}
                    autoPlay={true}
                    muted={true}
                    width='100%'
                    height={'auto'}
                    loop={true}

                >
                    <source
                        src="https://cdn.agenciaenos.com/uploads/Enos_PW_Home_Video_02_SEP22.mp4"
                        type="video/mp4"
                    />
                </video>
*/

export default function ContactoPage() {
    React.useEffect(() => {
        document.body.classList.toggle("contacto-page");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("contacto-page");
        };
       
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        alert('The link was clicked.');
      }
      //onClick={handleClick}

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Enos - Contacto</title>
                <meta name="description" content="Ofrecemos soluciones para cualquier tipo de negocio utilizando la metodología Growth como base para una estrategia digital directa. ¡Contáctanos y genera leads de calidad!" />
                <link rel="canonical" href="https://agenciaenos.com/contacto" />
                <meta property="og:locale" content="es_MX" />
		<meta property="og:site_name" content="enos - Exploradores • Numéricos • Observadores • Sistemáticos" />
            </Helmet>
            <Header />
                 <div className="bg bgContacto"></div>
                <div className="heroBackdropOne"></div>
                <div className="heroBackdrop"></div>
                <motion.main
                initial={{ opacity: 0, x: -200, y: 0 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0, x: 0, y: -100 }}
                transition={{ type: 'linear' }}
            >
                <section className="section">
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <div className="w-md-100 mt-4 d-inline-block pb-4 mb-4 ps-4 pe-4 ps-lg-0 pe-lg-0">
                                    <p className="text-center text-white">Mándanos tu ¡Houston, tenemos un problema!</p>
                                    <h1 className="text-center text-white h2 mb-3">Queremos llevarte
                                        al espacio.</h1>
                                    <Form className="contactForm" id="react_contact_form" action="https://api.leads.enosgrowth.com/forms/getdata-react.php" method="POST">
                                        <Form.Group className="mb-3">
                                            <Row>
                                                <Col xs={12} lg={6} controlId="formBasicName" className="mb-3 mb-lg-0">
                                                    <Form.Label className="d-none">Nombre</Form.Label>
                                                    <Form.Control type="text" placeholder="Nombre" id="form-field-contact_name" name="answer_name" required/>
                                                </Col>
                                                <Col xs={12} lg={6} controlId="formBasicPhone">
                                                    <Form.Label className="d-none">Teléfono</Form.Label>
                                                    <Form.Control type="tel" placeholder="Teléfono" id="form-field-contact_phone"  name="answer_phone" minlength="10" maxlength="10" required/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Row>
                                                <Col xs={12} lg={6} controlId="formBasicEmail" className="mb-3 mb-lg-0">
                                                    <Form.Label className="d-none">Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Correo" id="form-field-contact_email" name="answer_email" required/>
                                                </Col>
                                                <Col xs={12} lg={6}>
                                                    <Form.Label className="d-none">Empresa</Form.Label>
                                                    <Form.Control type="text" placeholder="Empresa" id="form-field-contact_company" name="answer_company" required/>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label className="d-none">Mensaje</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Mensaje"
                                                id="form-field-contact_message"
                                                name="answer_message"
                                                required
                                                style={{ height: '100px' }}
                                            />
                                        </Form.Group>
                                        <Form.Control type="hidden" name="enos_id_form" value="1" />
                                        <Form.Control type="hidden" name="enos_form" value="contact" />
                                        <Form.Control type="hidden" name="enos_url" value="https://agenciaenos.com/contacto" />
                                        <Form.Control type="hidden" name="enos_action" value="saveForm" />


                                        <Button variant="primary" type="submit">
                                            Enviar
                                        </Button>
                                    </Form>

                                </div>

                            </Col>
                            <Col xs={12} lg={6}>
                                <div className="w-md-100 mt-4 d-inline-block pb-4 mb-4 ps-4 pe-4 ps-lg-0 pe-lg-0">
                                    <h1 className="text-center text-white h2 mb-3">Contacto</h1>
                                    <Row className="d-flex align-items-center mb-4">
                                        <Col xs={2} className="p-0">
                                            <a href="mailto:contacto@enosgrowth.com" target={"_blank"}
                                                rel={"noreferrer"}><FontAwesomeIcon icon={faEnvelope} className="icoContact" /></a>
                                        </Col>
                                        <Col xs={10} className="text-start text-white">
                                            <h4 className="small mb-0">Correo</h4>
                                            <p className="strong mb-0">  <a href="mailto:contacto@enosgrowth.com" target={"_blank"}
                                                rel={"noreferrer"} className="text-white">contacto@enosgrowth.com</a></p>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex align-items-center mb-3">
                                        <Col xs={2} className="p-0">
                                            <a href="https://api.whatsapp.com/send?phone=525579984418" target={"_blank"}
                                                rel={"noreferrer"}><FontAwesomeIcon icon={faWhatsapp} className="icoContact" /></a>
                                        </Col>
                                        <Col xs={10} className="text-start text-white">
                                            <h4 className="small mb-0">Whatsapp</h4>
                                            <p className="strong mb-0">  <a href="https://api.whatsapp.com/send?phone=525579984418" target={"_blank"}
                                                rel={"noreferrer"} className="text-white">+52 55 7998-4418</a></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-4"></Col>
                                    </Row>
                                    <Row className="d-flex align-items-center mb-4">
                                        <Col className="p-0">
                                            <Card style={{ width: '100%' }} className="cardSuscribe">
                                                <Card.Body className="cardBody">
                                                    <Card.Title> <h3 className="text-center text-white h5 mb-1">Subscribe</h3></Card.Title>
                                                    <Card.Text>
                                                        <Form className="subscribeForm" id="react_subscribe_form" action="https://api.leads.enosgrowth.com/forms/getdata-react.php" method="POST">

                                                            <Form.Group className="mb-3">
                                                                <Form.Label controlId="formBasicEmail" className="d-none">Email</Form.Label>
                                                                <Form.Control type="email" placeholder="Correo" name="answer_email" required/>

                                                            </Form.Group>

                                                            <Form.Control type="hidden" name="enos_id_form" value="2" />
                                                            <Form.Control type="hidden" name="enos_form" value="newslette" />
                                                            <Form.Control type="hidden" name="enos_url" value="https://agenciaenos.com/contacto" />
                                                            <Form.Control type="hidden" name="enos_action" value="saveForm" />


                                                            <Button variant="primary" type="submit">
                                                                Enviar
                                                            </Button>
                                                        </Form>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>

                                    
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <RemoteNavigation />
                    </Container>
                    
                </section>
                <div className="endSpacing"></div>
            </motion.main>
        </>
    );
}
