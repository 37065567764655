
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { motion } from "framer-motion";


import Header from "./../../components/Header/Header.js";
import RemoteNavigation from "../../components/RemoteNavigation/RemoteNavigation.js";



export default function ServiciosPage() {
  React.useEffect(() => {
    document.body.classList.toggle("nosotros-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("nosotros-page");
    };
  }, []);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Enos - Servicios</title>
        <meta name="description" content="Toda la oferta que puedes obtener para tener un crecimiento acelerado con los mejores servicios" />
        <link rel="canonical" href="https://agenciaenos.com/servicios" />
        <meta property="og:locale" content="es_MX" />
		<meta property="og:site_name" content="enos - Exploradores • Numéricos • Observadores • Sistemáticos" />
      </Helmet>
      <Header />
         <div className="bg bgServicios"></div>
        <div className="heroBackdropOne"></div>
        <div className="heroBackdrop"></div>
        <motion.main
        initial={{ opacity: 0, x: -200, y: 0 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, x: 0, y: -100 }}
        transition={{ type: 'linear' }}
      >
        <section className="section">
          <Container>
            <Row>
              <Col>
                <div className="w-md-75 d-inline-block">
                  <h1 className="text-center text-white">SERVICIOS</h1>
                  <p className="text-center text-white">El tiempo es relativo, puede estirarse y apretarse, pero no puede correr para atrás, sólo podemos avanzar hacia adelante y para hacerlo enos te ayudará, nuestros servicios están pensados para ampliar tu negocio.</p>
                  <div className="text-center">
                    <a href="/contacto">
                      <Link to="/contacto"><Button variant="primary"> Contacto</Button></Link></a>
                  </div>
                  
                </div>
                <div className="w-md-80 mt-4 d-inline-block pb-4 mb-4 ps-4 pe-4 ps-lg-0 pe-lg-0">
                    <Row className="d-flex align-items-center justify-content-center">
                      <Col xs={12} lg={4} className="p-2 mt-4">
                        <Card style={{ width: '100%' }} className="cardServices">
                          <Card.Body className="cardBody">
                           
                            <Card.Title className="text-start cardTitle text-white text-center">Estrategía
                            </Card.Title>
                            <Card.Text className="text-start cardText text-white text-center">
                            Assessment, CRM, Integración,Buyer persona, Insights, Estrategia, Benchmark, Performance.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} lg={4} className="p-2 mt-4">
                        <Card style={{ width: '100%' }} className="cardServices">
                          <Card.Body className="cardBody">
                           
                            <Card.Title className="text-start cardTitle text-white text-center">DISEÑO
                            </Card.Title>
                            <Card.Text className="text-start cardText text-white text-center">
                            Branding, Ilustración, Wireframes, MockUp, Renders, Modelado 3D.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} lg={4} className="p-2 mt-4">
                        <Card style={{ width: '100%' }} className="cardServices">
                          <Card.Body className="cardBody">
                           
                            <Card.Title className="text-start cardTitle text-white text-center">SOCIAL MEDIA
                            </Card.Title>
                            <Card.Text className="text-start cardText text-white text-center">
                            RR.SS, community management (manager), Email MKT, inbound, influence. 
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} lg={4} className="p-2 mt-4">
                        <Card style={{ width: '100%' }} className="cardServices">
                          <Card.Body className="cardBody">
                           
                            <Card.Title className="text-start cardTitle text-white text-center">compra de medios
                            </Card.Title>
                            <Card.Text className="text-start cardText text-white text-center">
                            SEM, Pauta de RR.SS.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} lg={4} className="p-2 mt-4">
                        <Card style={{ width: '100%' }} className="cardServices">
                          <Card.Body className="cardBody">
                           
                            <Card.Title className="text-start cardTitle text-white text-center">WEB
                            </Card.Title>
                            <Card.Text className="text-start cardText text-white text-center">
                            Landing, Blog, Data, UX / UI, Optimizacion, Autoridad, Link Building, Automatizacion, SEO, Keywords.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} lg={4} className="p-2 mt-4">
                        <Card style={{ width: '100%' }} className="cardServices">
                          <Card.Body className="cardBody">
                           
                            <Card.Title className="text-start cardTitle text-white text-center">MULTIMEDIA
                            </Card.Title>
                            <Card.Text className="text-start cardText text-white text-center">
                            Contenidos, Fotografía, Video, Animación.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                <RemoteNavigation />
              </Col>
            </Row>
          </Container>
        </section>
      </motion.main>
    </>
  );
}
