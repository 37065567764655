
import React, {useState, useEffect} from 'react'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
//import withTransition from "../HOC/withTransition";
//import React, { useEffect, useRef } from 'react';

//Components
//import Loader from '../components/Loader/Loader.js';
import Header from "./../components/Header/Header.js";
import RemoteNavigation from "../components/RemoteNavigation/RemoteNavigation.js";

import { motion } from "framer-motion";


// sections for this page/view
/*
 <video className="video"
                    controls={false}
                    autoPlay={true}
                    muted={true}
                    width='100%'
                    height={'auto'}
                    loop={true}

                >
                    <source
                        src="https://cdn.agenciaenos.com/uploads/Enos_PW_Home_Video_02_SEP22.mp4"
                        type="video/mp4"
                    />
                </video>

*/

export default function Index() {
    //const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        document.body.classList.toggle("index-page");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("index-page");
        };

       
    }, []);

    /*
    useEffect(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 1500)
      }, [])
      */

    //if(isLoading)
    //return <div className={`homeMainContainer`}><Loader/></div>
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Enos - Business Growth Agency</title>
                <meta name="description" content="Conoce nuestra metodología Growth enfocada en atracción de clientes, exposición y posicionamiento de marca."></meta>
                <link rel="canonical" href="https://agenciaenos.com/" />
                <meta property="og:locale" content="es_MX" />
                <meta property="og:type" content="website" />
		<meta property="og:title" content="Enos - Growth Marketing" />
            </Helmet>
            <Header />
            
               <div className="bg bgInicio"></div>
                <div className="heroBackdropOne"></div>
                <div className="heroBackdrop"></div>
                <motion.main
                initial={{ opacity: 0, x: -200, y: 0 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0, x: 0, y: -100 }}
                transition={{ type: 'linear' }}
            >
                <section className="section">
                    <Container className="d-flex align-items-center">
                        <Row>
                            <Col>
                            <div className="w-md-75 d-inline-block">
                                <h1 className="text-center text-white">SOMOS TU AGENCIA DE MKT</h1>
                                <p className="text-center text-white">Nos hemos definido por la capacidad de superar lo imposible, cuando nos atrevemos a apuntar más alto, a romper barreras, a alcanzar las estrellas, a dar a conocer lo desconocido. Porque nuestros mayores logros no pueden quedar atrás, nuestro destino está por encima de nosotros. -Interstellar</p>
                                <div className="text-center">
                                    <a href="/contacto">
                                        <Link to="/contacto"><Button variant="primary"> Contáctanos</Button></Link></a>
                                </div>
                                </div>
                                <div className="w-md-75 mt-4 pt-4 d-inline-block">
                                <p className="text-center text-white">Somos un grupo de expertos seleccionados de lo mejor, de lo mejor, de lo mejor (no es un error somos lo mejor), en diseñar estrategias de Marketing Digital rápidas de implementar, enfocadas en generación de leads y exposición de marca, con metodologías especializadas en explotar tu revenue a través de distintos canales. 
                                </p>
                                </div>
                                <RemoteNavigation/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                
            </motion.main>
            
        </>
    );
}

/*

position-absolute top-100 start-50 translate-middl
*/