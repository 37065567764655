
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Card, Title, Body, Text, Subtitle } from 'react-bootstrap';
import { motion } from "framer-motion";


import Header from "./../../components/Header/Header.js";
import RemoteNavigation from "../../components/RemoteNavigation/RemoteNavigation.js";



export default function EquipoPage() {
  React.useEffect(() => {
    document.body.classList.toggle("equipo-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("equipo-page");
    };
  }, []);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta charSet="utf-8" />
                <title>Enos - El Equipo</title>
                <meta name="description" content="Hacemos que tu marca, tu producto o lo que tú quieras deje su huella en el mundo y las estrellas." />
                <link rel="canonical" href="https://agenciaenos.com/equipo" />
                <meta property="og:locale" content="es_MX" />
		<meta property="og:site_name" content="enos - Exploradores • Numéricos • Observadores • Sistemáticos" />
      </Helmet>
      <Header />
         <div className="bg bgEquipo"></div>
        <div className="heroBackdropOne"></div>
        <div className="heroBackdrop"></div>
        <motion.main
        initial={{ opacity: 0, x: -200, y: 0 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, x: 0, y: -100 }}
        transition={{ type: 'linear' }}
      >
        <section className="section">
          <Container>
            <Row>
              <Col>
                <div className="w-md-75 d-inline-block">
                  <h1 className="text-center text-white">¡Nos vemos en el espacio!</h1>
                  <p className="text-center text-white">Toma tu asiento, prepárate para el viaje y comienza a disfrutar el viaje a través del tiempo y el espacio. Donde tu marca, tu producto o lo que tú quieras dejará su huella en el mundo y las estrellas.</p>
                  <div className="text-center">
                    <a href="/contacto">
                      <Link to="/contacto"><Button variant="primary"> Contacto</Button></Link></a>
                  </div>
                </div>
                <div className="w-md-80 mt-4 d-inline-block pb-4 mb-4 ps-4 pe-4 ps-lg-0 pe-lg-0">
                  <Row className="d-flex align-items-center justify-content-center">
                    <Col xs={12} lg={4} className="p-2 mt-4">
                      <Card style={{ width: '100%' }} className="cardTeam">
                        <Card.Body className="cardBody">
                          <Card.Img variant="top" src="https://cdn.agenciaenos.com/uploads/team/AvatarManu.svg" className="teamImg" />
                          <Card.Title className="text-start">Manuel Miranda Ruiz
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-start">CEO</Card.Subtitle>
                          <Card.Text className="text-start">
                          Amante del work smart, la producción de contenido de calidad y los snickers. Más de 13 años de experiencia es un ferviente impulsor de las nuevas formas de hacer Marketing y de la estrategia con data como soporte.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} lg={4} className="p-2 mt-4">
                    <Card style={{ width: '100%' }} className="cardTeam">
                        <Card.Body className="cardBody">
                          <Card.Img variant="top" src="https://cdn.agenciaenos.com/uploads/team/AvatarMel.svg" className="teamImg" />
                          <Card.Title className="text-start">Melissa Cortés Cassio
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-start">Creative Director</Card.Subtitle>
                          <Card.Text className="text-start">
                          Entusiasta de los ponies, comenzó su carrera como Copywriter tanto en inglés como en español, su pasión por la creatividad la ha llevado a trabajar con marcas internacionales y nacionales. Le dice soccer al fútbol.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} lg={4} className="p-2 mt-4">
                    <Card style={{ width: '100%' }} className="cardTeam">
                        <Card.Body className="cardBody">
                          <Card.Img variant="top" src="https://cdn.agenciaenos.com/uploads/team/AvatarYaz.svg" className="teamImg" />
                          <Card.Title className="text-start">Yazmín Gurrola García
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-start">Art Director</Card.Subtitle>
                          <Card.Text className="text-start">
                          Ferviente amante de la animación e ilustración esta talentosa diseñadora gráfica, en todo incluye lomitos, pero también vive por los monos chinos (si quieres discutir de cualquier cosa japonesa, será tu mejor aliada).
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} lg={4} className="p-2 mt-4">
                    <Card style={{ width: '100%' }} className="cardTeam">
                        <Card.Body className="cardBody">
                          <Card.Img variant="top" src="https://cdn.agenciaenos.com/uploads/team/AvatarNancy.svg" className="teamImg" />
                          <Card.Title className="text-start">Nancy Duran Romero
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-start">Social Media Manager</Card.Subtitle>
                          <Card.Text className="text-start">
                          La chica de las redes sociales es una experta en community management, insights y benchmarks. Todo lo sube a redes, todo. Tiene una bendi perruna, es fan de Hello Kitty y existe por Chris Evans.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} lg={4} className="p-2 mt-4">
                    <Card style={{ width: '100%' }} className="cardTeam">
                        <Card.Body className="cardBody">
                          <Card.Img variant="top" src="https://cdn.agenciaenos.com/uploads/team/AvatarGabo.svg" className="teamImg" />
                          <Card.Title className="text-start">Gabriel García Barrera
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted text-start">Web Director</Card.Subtitle>
                          <Card.Text className="text-start">
                          Este amante de la comida méxicana, la música y el buen beber tiene experiencia como Project Manager, Webmaster Sr, Desarrollador Web, SEO/SEM Manager y Diseñador UX/PX. Todo lo que se te ocurra para web, lo hace realidad. 
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>


                </div>
                <RemoteNavigation />
              </Col>
            </Row>
          </Container>
        </section>
      </motion.main>
    </>
  );
}
